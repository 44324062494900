<template>
    <div>
        <div v-if="additional_copy_options.length > 0">
            <div class="row">
                <div class="col">
                    <h3>
                        GET A COPY OF THE SAME PORTRAIT
                        <InfoPopoverComponent
                            title="Second Copy"
                        >
                            <p>Get a duplicate canvas at a discounted price—perfect for gifting or sharing a family portrait across different homes. Keep one for yourself, and share the joy with loved ones!</p>
                        </InfoPopoverComponent>
                    </h3>
                </div>
            </div>
            <div class="row options">
                <div
                    class="col"
                    v-for="option in additional_copy_options"
                    :key="option.label"
                >
                    <a
                        @click.prevent="selectOption(option)"
                        href="#"
                        ref="additional_copy_option"
                        class="option"
                        :class="{
                            selected: option.selected,
                            disabled: option.disabled,
                            'one-extra-copy': option.label === 'one_extra_copy',
                        }"
                    >
                        <p>
                            {{ label_map[option.label] || option.label }}
                        </p>
                        <p>
                            {{ formatPrice(option) }}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useOrderStore } from "@/stores/order";
import { useCartStore } from "@/stores/cart";
import { currencySymbol } from "@/utilities/currency";
import InfoPopoverComponent from "@/components/InfoPopoverComponent.vue";

export default {
    name: "GetACopy",
    components: { InfoPopoverComponent },
    props: {
        order: Object,
    },
    data() {
        return {
            additional_copy_options: [],
            orderStore: useOrderStore(),
            cartStore: useCartStore(),
            label_map: {
                one_extra_copy: "Get a copy",
            },
        };
    },
    mounted() {
        let availableAdditionalCopyUpgrades =
            this.orderStore.order_data.available_upgrades.additional_copy;

        if (availableAdditionalCopyUpgrades) {
            let additional_copy_options = [];

            if (!this.order.copyPurchased) {
                additional_copy_options.push({
                    current: true,
                    selected: true,
                    label: "No copy",
                    currency: "USD",
                    amount: 0,
                    is_void: true,
                });
            }

            for (let i = 0; i < availableAdditionalCopyUpgrades.length; i++) {
                let elm = Object.assign({}, availableAdditionalCopyUpgrades[i]);

                elm.current = false;
                elm.selected = false;
                elm.label = elm.additional_copy;

                if (elm.additional_copy === this.order.copyPurchased) {
                    elm.current = true;
                    elm.selected = true;
                }

                additional_copy_options.push(elm);
            }

            if (additional_copy_options.length > 1) {
                this.additional_copy_options = additional_copy_options;
            }
        }
    },
    unmounted() {
        this.$emit("additionalCopyUpdate", 0);
    },
    methods: {
        selectOption(option) {
            this.additional_copy_options.forEach((elm) => {
                elm.selected = elm.label === option.label;

                if (elm.selected) {
                    if (elm.is_void) {
                        this.cartStore.removeFromCart("additional_copy");
                    } else {
                        this.cartStore.addToCart(this.order, {
                            additional_copy: elm,
                        });
                    }

                    this.$emit("additionalCopyUpdate", elm.amount);
                }
            });
        },
        getUpgradeValue(option) {
            return option.amount;
        },
        formatPrice(option) {
            let value = this.getUpgradeValue(option);

            if (option.current) {
                return "Current";
            }

            return "+" + currencySymbol(option.currency) + value;
        },
    },
};
</script>

<style scoped>
h3 {
    margin: 16px 0 8px;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
}

.col {
    padding-left: 6px;
    padding-right: 6px;
}

a.option {
    text-align: center;
    color: #392f5a;
    background-color: #ebeaee;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: block;
    padding: 8px 14px;
    outline: 1px solid #dad8e0;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
    }

    &.selected {
        outline: 2px solid #392f5a;
    }

    &.disabled {
        cursor: default;
        background: lighten(#ebeaee, 10%);
        outline-color: lighten(#8d8b88, 2%);
        color: lighten(#9084b5, 2%);
    }

    &.one-extra-copy {
        background-image: url("@/assets/images/upsells/additional_copy/one_extra_copy.png");
        background-position: right 4px center;
        background-repeat: no-repeat;
        background-size: 33%;
        padding-right: 55px;
    }

    p {
        margin: 5px 0;
    }
}
</style>
