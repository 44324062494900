<template>
    <div class="images_slider_block">
        <div v-for="slide in slides" :key="slide">
            <expandable-image :src="slide" :frame="frame" />
        </div>
    </div>
</template>

<script>
import ExpandableImage from "./ExpandableImage.vue";

//var ZoomComponent = Vue.component('zoom-on-hover', ZoomOnHover);
export default {
    name: "ImageSlider",
    components: { ExpandableImage },
    props: ["slides", "frame"],
};
</script>
