<template>
    <div @click.stop="onClick" class="revision_block">
        <div class="center_revision absolute-center-block approve_block">
            <h2>Are you sure you want to approve {{ item }} design?</h2>
            <p>
                Please be aware that once you've approved your portrait, no
                further edits, changes or upgrades can be made to it.
            </p>
            <div class="button active button_yes" @click.stop="submitForm">
                Yes, approve designs!
            </div>
            <div
                @click.stop="$emit('close')"
                class="button button_no_bg button_no active"
            >
                Not just yet
            </div>
        </div>
    </div>
</template>

<script>
import { useOrderStore } from "@/stores/order";
import * as Sentry from "@sentry/vue";
import { useAuthStore } from "@/stores/auth";

export default {
    name: "ApprovalModal",
    props: {
        item: String,
    },
    emits: {
        close: () => true,
    },
    data() {
        return {
            loading: false,
            orderStore: useOrderStore(),
            authStore: useAuthStore(),
        };
    },
    created() {
        document.addEventListener("keydown", this._onEscape);
    },
    unmounted() {
        document.removeEventListener("keydown", this._onEscape);
    },
    methods: {
        _onEscape(e) {
            if (e.keyCode === 27) {
                this.$emit("close");
            }
        },
        submitForm() {
            if (this.loading) return;

            this.$Progress.start();
            this.loading = true;

            this.orderStore
                .approveDesign()
                .then(() => {
                    this.$Progress.finish();
                })
                .catch((e) => {
                    this.$Progress.fail();

                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail:
                            e?.response?.data ||
                            "There was a server error loading your order, please try again later!",
                        life: 5000,
                    });

                    if (e?.response?.status >= 500) {
                        Sentry.captureException(e);
                    } else if (e?.response?.status !== 422) {
                        this.authStore.logOut().then(() => {
                            this.$router.push({
                                name: "login",
                                query: {
                                    redirect: this.$route.fullPath,
                                },
                            });
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit("close");
                });
        },
        onClick(e) {
            if (e.target.classList.contains("revision_block")) {
                this.$emit("close");
            }
        },
    },
};
</script>

<style scoped></style>
