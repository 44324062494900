<template>
    <div
        :class="{
            progress_bar: true,
            digital: orderStore.currentOrder.itemIsDigital,
        }"
        :style="{ 'margin-left': leftMargin }"
    >
        <input type="hidden" />
        <div class="long_line"></div>
        <div
            class="step waiting_for_approve"
            :class="{ completed: 0 < step, waiting_for_approve: 0 === step }"
        >
            <div class="step_line"></div>
            <div class="step-block">
                <div class="step_name">
                    <p>
                        Processing <br />
                        order
                    </p>
                </div>
            </div>
        </div>
        <div
            class="step"
            :class="{ completed: 1 < step, waiting_for_approve: 1 === step }"
        >
            <div class="step_line"></div>
            <div class="step-block">
                <div class="step_name">
                    <p>Ready to<br />send to Artist</p>
                </div>
            </div>
        </div>
        <div
            class="step"
            :class="{ completed: 2 < step, waiting_for_approve: 2 === step }"
        >
            <div class="step_line"></div>
            <div class="step-block">
                <div class="step_name">
                    <p>Sent to<br />Artist</p>
                </div>
            </div>
        </div>
        <div
            class="step"
            :class="{ completed: 3 < step, waiting_for_approve: 3 === step }"
        >
            <div class="step_line"></div>
            <div class="step-block">
                <div class="step_name">
                    <p>Approved by<br />Customer</p>
                </div>
            </div>
        </div>
        <div
            class="step"
            :class="{ completed: 4 < step, waiting_for_approve: 4 === step }"
        >
            <div class="step_line"></div>
            <div class="step-block">
                <div
                    class="step_name"
                    v-if="orderStore.currentOrder.itemIsDigital"
                >
                    <p>Digital file<br />ready to download</p>
                </div>
                <div class="step_name" v-else>
                    <p>Sent to<br />Print</p>
                </div>
            </div>
        </div>
        <div
            class="step"
            v-if="!orderStore.currentOrder.itemIsDigital"
            :class="{ completed: 5 < step, waiting_for_approve: 5 === step }"
        >
            <div class="step_line"></div>
            <div class="step-block">
                <div class="step_name">
                    <p>Shipped</p>
                </div>
            </div>
        </div>
        <div
            class="step"
            v-if="!orderStore.currentOrder.itemIsDigital"
            :class="{ completed: 6 < step, waiting_for_approve: 6 === step }"
        >
            <div class="step_line"></div>
            <div class="step-block">
                <div class="step_name">
                    <p>Delivered</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useOrderStore } from "@/stores/order";

const stepLength = 7;
export default {
    name: "ProgressBar",
    created: function () {
        window.addEventListener("resize", this.setLeftMargin);
    },
    unmounted: function () {
        window.removeEventListener("resize", this.setLeftMargin);
    },
    data() {
        return {
            orderStore: useOrderStore(),
        };
    },
    computed: {
        step() {
            let progressbar = this.orderStore.order_data?.steps_array?.find(
                (e) => e.id === "progressbar"
            );

            return progressbar ? Math.floor(progressbar.step) : 0;
        },
        leftMargin() {
            if (!this.$el) return "0px";

            let margin_left = 0;
            let one_step_width = this.$el.offsetWidth / stepLength;

            let window_width = window.innerWidth;
            let currentStep = this.step;
            if (window_width > 550) {
                if (currentStep > 4) {
                    margin_left += -one_step_width;
                }
            } else {
                if (currentStep > 5) {
                    currentStep = 5;
                }
                if (currentStep > 2) {
                    margin_left += -((currentStep - 1) * one_step_width);
                }
            }

            return window_width < 1160 ? margin_left + "px" : "0px";
        },
    },
};
</script>

<style scoped>
.step .step_line {
    width: 0%;
    transition-property: width;
    transition-duration: 0.5s;
}

.step.completed .step_line,
.step.waiting_for_approve .step_line {
    width: 100%;
}
</style>
