<template>
    <div>
        <div v-if="warranty_options.length > 0">
            <div class="row">
                <div class="col">
                    <h3>
                        GET ADDITIONAL 5 YEARS WARRANTY
                        <InfoPopoverComponent
                            title="The 5-year Warranty"
                        >
                            <p>This warranty covers material defects, assembly errors, and structural failures under normal use. It does not cover intentional damage, exposure to extreme conditions, improper handling, accidental damage, alterations, misuse, neglect, aesthetic changes, or third-party interference.</p>
                        </InfoPopoverComponent>
                    </h3>
                </div>
            </div>
            <div class="row options">
                <div
                    class="col"
                    v-for="option in warranty_options"
                    :key="option.label"
                >
                    <a
                        @click.prevent="selectOption(option)"
                        href="#"
                        class="option"
                        ref="warranty_option"
                        :class="{
                            selected: option.selected,
                            disabled: option.disabled,
                            'five-years-warranty':
                                option.label === '5_years_warranty',
                        }"
                    >
                        <p style="text-transform: capitalize">
                            {{ capitalize(option.label, "_") }}
                        </p>
                        <p>
                            {{ formatPrice(option) }}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useOrderStore } from "@/stores/order";
import { capitalize } from "@/utilities/strings";
import { useCartStore } from "@/stores/cart";
import { currencySymbol } from "@/utilities/currency";
import InfoPopoverComponent from "@/components/InfoPopoverComponent.vue";

export default {
    name: "WarrantyUpgrade",
    components: { InfoPopoverComponent },
    props: {
        order: Object,
    },
    data() {
        return {
            warranty_options: [],
            orderStore: useOrderStore(),
            cartStore: useCartStore(),
        };
    },
    mounted() {
        let availableWarrantyUpgrades =
            this.orderStore.order_data.available_additional_upgrades.warranty;

        if (availableWarrantyUpgrades) {
            let warranty_options = [];

            if (!this.order.warranty) {
                warranty_options.push({
                    current: !this.order.warranty,
                    selected: !this.order.warranty,
                    label: "No additional warranty",
                    amount: 0,
                    currency: "USD",
                    is_void: true,
                });
            }

            for (let i = 0; i < availableWarrantyUpgrades.length; i++) {
                let elm = Object.assign({}, availableWarrantyUpgrades[i]);

                elm.current = false;
                elm.selected = false;
                elm.label = elm.warranty;

                if (elm.warranty === this.order.warranty) {
                    elm.current = true;
                    elm.selected = true;
                }

                warranty_options.push(elm);
            }

            if (warranty_options.length > 1) {
                this.warranty_options = warranty_options;
            }
        }
    },
    unmounted() {
        this.cartStore.removeFromCart("warranty");
        this.$emit("warrantyUpdate", 0);
    },
    methods: {
        capitalize,
        selectOption(option) {
            this.warranty_options.forEach((elm) => {
                elm.selected = elm.label === option.label;

                if (elm.selected) {
                    if (elm.is_void) {
                        this.cartStore.removeFromCart("warranty");
                    } else {
                        this.cartStore.addToCart(this.order, { warranty: elm });
                    }

                    this.$emit("warrantyUpdate", elm.amount);
                }
            });
        },
        getUpgradeValue(option) {
            return option.amount;
        },
        formatPrice(option) {
            let value = this.getUpgradeValue(option);

            if (option.current) {
                return "Current";
            }

            return (
                "+" +
                currencySymbol(option.currency) +
                (value * this.order.quantity)
            );
        },
    },
};
</script>

<style scoped lang="scss">
h3 {
    margin: 16px 0 8px;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
}

.col {
    padding-left: 6px;
    padding-right: 6px;
}

a.option {
    text-align: center;
    color: #392f5a;
    background-color: #ebeaee;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: block;
    padding: 8px 14px;
    outline: 1px solid #dad8e0;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
    }

    &.selected {
        outline: 2px solid #392f5a;
    }

    &.disabled {
        cursor: default;
        background: lighten(#ebeaee, 10%);
        outline-color: lighten(#8d8b88, 2%);
        color: lighten(#9084b5, 2%);
    }

    &.five-years-warranty {
        background-image: url("@/assets/images/upsells/warranty/5_years_warranty.png");
        background-size: 33%;
        background-position: calc(100% + 4px) calc(50% + 1px);
        background-repeat: no-repeat;
        padding-right: 40px;
    }

    p {
        margin: 5px 0;
    }
}
</style>
