<template>
    <div
        class="expandable-image"
        :class="{
            expanded: expanded,
            'has-border': ['walnut', 'black'].includes(dynamicFrame),
            'border-brown': dynamicFrame === 'walnut',
            'border-black': dynamicFrame === 'black',
        }"
        @click="expanded = true"
    >
        <div class="block">
            <i v-if="expanded" class="close-button">
                <img src="/images/closebtn.svg" alt="" />
            </i>
            <i v-if="!expanded" class="expand-button">
                <div class="zoom_in">
                    <img src="/images/zoomin.png" alt="" />
                </div>
            </i>
            <img v-bind="$attrs" :class="{expanded}" class="product-image" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        closeOnBackgroundClick: {
            type: Boolean,
            default: false,
        },
        frame: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            expanded: false,
            closeButtonRef: null,
            dynamicFrame: this.frame,
        };
    },
    mounted() {
        this.emitter.on(
            "frame-changed",
            (frame) => (this.dynamicFrame = frame)
        );
    },
    unmounted() {
        document.removeEventListener("keydown", this._onEscape);
    },

    methods: {
        closeImage() {
            this.expanded = false;
        },

        _onEscape(e) {
            if (e.keyCode === 27) {
                this.closeImage();
            }
        },

        freezeVp(e) {
            e.preventDefault();
        },

        onExpandedImageClick(e) {
            e.stopPropagation();
            const image = this.cloned.querySelector("img");
            const imagePosition = this.getRenderedSize(
                image.width,
                image.height,
                image.naturalWidth,
                image.naturalHeight
            );
            if (
                e.clientX < imagePosition.left ||
                e.clientX > imagePosition.right ||
                e.clientY < imagePosition.top ||
                e.clientY > imagePosition.bottom
            ) {
                this.expanded = false;
            }
        },

        getRenderedSize(cWidth, cHeight, oWidth, oHeight) {
            const oRatio =
                oWidth > oHeight ? oWidth / oHeight : oHeight / oWidth;
            const width = oWidth >= oHeight ? oRatio * cHeight : cWidth;
            const height = oHeight > oWidth ? oRatio * cWidth : cHeight;
            const left = (this.cloned.clientWidth - width) / 2;
            const right = left + width;
            const top = (this.cloned.clientHeight - height) / 2;
            const bottom = top + height;
            return { left, top, right, bottom };
        },
    },

    watch: {
        expanded(status) {
            this.$nextTick(() => {
                if (status) {
                    this.cloned = this.$el.cloneNode(true);
                    this.closeButtonRef =
                        this.cloned.querySelector(".close-button");
                    this.closeButtonRef.addEventListener(
                        "click",
                        this.closeImage
                    );

                    document.body.appendChild(this.cloned);
                    document.body.style.overflow = "hidden";
                    this.cloned.addEventListener(
                        "touchmove",
                        this.freezeVp,
                        false
                    );
                    this.cloned.addEventListener(
                        "click",
                        this.onExpandedImageClick
                    );

                    setTimeout(() => {
                        this.cloned.style.opacity = 1;
                    }, 0);
                    document.addEventListener("keydown", this._onEscape);
                    document.addEventListener("keydown", this._onEscape);
                } else {
                    this.cloned.style.opacity = 0;
                    this.cloned.removeEventListener(
                        "touchmove",
                        this.freezeVp,
                        false
                    );
                    if (this.closeOnBackgroundClick) {
                        this.cloned.removeEventListener(
                            "click",
                            this.onExpandedImageClick
                        );
                    }
                    setTimeout(() => {
                        this.closeButtonRef.removeEventListener(
                            "click",
                            this.closeImage
                        );
                        this.cloned.remove();
                        this.cloned = null;
                        this.closeButtonRef = null;
                        document.body.style.overflow = "auto";
                    }, 250);
                }
            });
        },
    },
};
</script>

<style>
.expandable-image {
    position: relative;
    transition: 0.25s opacity;
    cursor: zoom-in;
}

body > .expandable-image.expanded {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8) !important;
    display: flex;
    align-items: center;
    opacity: 0;
    padding-bottom: 0 !important;
    cursor: default;
}

body > .expandable-image.expanded > .block {
    width: 100%;
    max-width: 1200px;
    max-height: 100%;
    object-fit: contain;
    margin: 0 auto;
    max-width: 540px !important;
    border: 15px solid #fff;
}

body > .expandable-image.expanded > .close-button {
    display: block;
}

.close-button {
    position: relative;
    top: 8px;
    right: 8px;
    cursor: pointer;
    float: right;
    height: 0px;
    z-index: 10;
}
.expand-button svg,
.close-button svg {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}
.expand-button svg path,
.close-button svg path {
    fill: #fff;
}
.expand-button {
    position: absolute;
    z-index: 999;
    right: 10px;
    top: 10px;
    align-items: center;
    justify-content: center;
    padding: 3px;
    transition: 0.2s opacity;
}

.expandable-image:hover .expand-button {
    opacity: 1;
}
.expand-button svg {
    width: 20px;
    height: 20px;
}
.expand-button path {
    fill: #fff;
}

@keyframes zoom-in {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

img.product-image {
    //animation: zoom-in 0.3s ease;
}

.expandable-image {
    border: 8px solid #fff;

    &.border-brown {
        border-color: #674e48;
    }

    &.border-black {
        border-color: #494a4c;
    }

    &.expanded {
        border: 0;
    }

    img {
        width: 100%;
    }
}

.has-border {
    border: 8px solid #fff;

    .product-image {
        border: 5px solid #000;

        &.expanded {
            border: 0;
        }
    }
}
</style>
