<template>
    <!----------------------------- SINGLE PRODUCT ----------------------------->
    <div
        @click="changeSelectedProduct"
        class="product_full_size variant_id_"
        data-cy="product-selector"
        :class="{ selected }"
    >
        <div class="block_padding_without_right">
            <div class="product">
                <div class="image">
                    <img :src="order.orderImage" alt="" />
                </div>
                <div class="text_and_properties">
                    <div class="title">{{ order.product }}</div>
                    <div class="grid-rows-2 properties_columns">
                        <div class="property">
                            <p>{{ capitalizeFirstLetter(order.orderSize) }}</p>
                        </div>
                        <div class="price text-left">
                            <!--                            <p>${{ order.orderPrice }}</p>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="status" :class="productStatus.toLowerCase().trim()">
            <p>{{ productStatus }}</p>
        </div>
    </div>
    <!----------------------------- SINGLE PRODUCT END ----------------------------->
</template>
<script>
import { stepToStatus } from "@/utilities/lineItemUtils";
import { useOrderStore } from "@/stores/order";
import { capitalizeFirstLetter } from "../utilities/strings";
export default {
    name: "Product",
    props: ["order"],
    data() {
        return {
            orderStore: useOrderStore(),
        };
    },
    mounted: function () {
        if (!this.orderStore.selectedProduct) {
            this.changeSelectedProduct();
        }
    },
    computed: {
        selected() {
            return this.orderStore.selectedProduct === this.order.id;
        },
        step() {
            return this.orderStore.getOrderStep(this.order.id);
        },
        productStatus() {
            return stepToStatus(this.step);
        },
    },
    methods: {
        capitalizeFirstLetter,
        changeSelectedProduct() {
            this.orderStore.changeSelectedProduct(this.order.id);
        },
    },
};
</script>

<style scoped>
.image img {
    width: 100%;
}
</style>
